<template>
  <div>
    <div class="flex flex-wrap justify-between mb-1">
      <div class="flex flex-row">
        <div>
          <h5 class="mr-4">Date Range</h5>
          <div
            class="p-3 mt-2 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
          >
            <date-range-picker
              ref="picker"
              :opens="'right'"
              :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
              :maxDate="date2"
              :timePicker="true"
              :showWeekNumbers="false"
              :showDropdowns="true"
              :autoApply="true"
              :appendToBody="true"
              :always-show-calendars="false"
              :linkedCalendars="true"
              v-model="dateRange"
              :control-container-class="'bg-transparent cursor-pointer'"
            >
              <template
                v-slot:input="picker"
                class="bg-transparent"
                style="min-width: 350px"
              >
                {{ picker.startDate | date }} - {{ picker.endDate | date }}
              </template>
            </date-range-picker>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
        </div>
        <div class="ml-6">
          <h5>Branch</h5>
          <vs-dropdown
            vs-trigger-click
            class="dd-actions cursor-pointer mb-4"
            v-if="$acl.check('admin')"
          >
            <div
              class="p-4 mt-2 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
            >
              {{
                currentBranch == null
                  ? 'All Branches'
                  : currentBranch.branch_name
              }}
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="changeBranch(null)">
                <span class="flex items-center">
                  <span>All Branches</span>
                </span>
              </vs-dropdown-item>
              <template>
                <vs-dropdown-item
                  @click="changeBranch(branch)"
                  v-for="(branch, index) in branchOptions"
                  v-bind:key="index"
                >
                  <span class="flex items-center">
                    <span>{{ branch.branch_name }}</span>
                  </span>
                </vs-dropdown-item>
              </template>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div
          class="ml-6"
          v-if="[tabs.sales_report, tabs.product_report].includes(currentTab)"
        >
          <h5>Customer</h5>
          <vs-dropdown
            vs-trigger-click
            class="dd-actions cursor-pointer mb-4"
            v-if="$acl.check('admin')"
          >
            <div
              class="p-4 mt-2 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
            >
              {{
                currentCustomer == null ? 'All Customers' : currentCustomer.name
              }}
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="changeCustomer(null)">
                <span class="flex items-center">
                  <span>All Customers</span>
                </span>
              </vs-dropdown-item>
              <template>
                <vs-dropdown-item
                  @click="changeCustomer(customer)"
                  v-for="(customer, index) in customerOptions"
                  v-bind:key="index"
                >
                  <span class="flex items-center">
                    <span>{{ customer.name }}</span>
                  </span>
                </vs-dropdown-item>
              </template>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div class="ml-6" v-if="[tabs.purchase_report].includes(currentTab)">
          <h5>Supplier</h5>
          <vs-dropdown
            vs-trigger-click
            class="dd-actions cursor-pointer mb-4"
            v-if="$acl.check('admin')"
          >
            <div
              class="p-4 mt-2 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full"
            >
              {{
                currentSupplier == null
                  ? 'All Suppliers'
                  : currentSupplier.supplier_name
              }}
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="changeSupplier(null)">
                <span class="flex items-center">
                  <span>All Suppliers</span>
                </span>
              </vs-dropdown-item>
              <template>
                <vs-dropdown-item
                  @click="changeSupplier(supplier)"
                  v-for="(supplier, index) in supplierOptions"
                  v-bind:key="index"
                >
                  <span class="flex items-center">
                    <span>{{ supplier.supplier_name }}</span>
                  </span>
                </vs-dropdown-item>
              </template>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>
      <feather-icon
        v-if="[tabs.sales_report, tabs.purchase_report].includes(currentTab)"
        icon="PrinterIcon"
        svg-classes="w-8 h-8 hover:text-primary stroke-current cursor-pointer"
        @click.stop="printSummary()"
      />
    </div>
    <vs-alert
      :active.sync="currency_warning"
      closable
      close-icon="icon-x-circle"
      icon-pack="feather"
    >
      <b>Note:</b> All figures are in
      <b>{{ $root.businessData.data.currency }}</b> unless otherwise stated.
    </vs-alert>
    <vs-tabs>
      <!--  -->
      <vs-tab label="Gross Sales" @click="changeTab(tabs.gross_sales)">
        <div class="vx-row">
          <!-- <div
            v-if="currentCustomer == null"
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4"
          >
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="TruckIcon"
              icon-right
              :statistic="POData ? POData.length : 0"
              statisticTitle="Total Supplies"
            />
          </div> -->

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ShoppingBagIcon"
              icon-right
              :statistic="total_sales"
              statisticTitle="Total Sales"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ShoppingCartIcon"
              icon-right
              :statistic="gross_sales"
              :statisticTitle="`Gross Sales (${$root.businessData.data.currency})`"
              color="success"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="TrendingUpIcon"
              icon-right
              :statistic="gross_profit"
              :statisticTitle="`Gross Profit (${$root.businessData.data.currency})`"
              color="success"
            />
          </div>
        </div>
        <div class="vx-row" v-if="payment_statistics != {}">
          <div
            class="vx-col w-full md:w-1/3 mb-base"
            v-for="payment in payment_statistics"
            :key="payment.id"
          >
            <vx-card :title="payment.n">
              <template slot="actions">
                <vx-tooltip
                  :text="'A summary of sales received using ' + payment.n"
                  position="bottom"
                >
                  <feather-icon
                    icon="HelpCircleIcon"
                    svgClasses="w-6 h-6 text-grey"
                  ></feather-icon>
                </vx-tooltip>
              </template>

              <!-- CHART -->
              <template slot="no-body">
                <div class="mt-10">
                  <vue-apex-charts
                    type="radialBar"
                    height="240"
                    :options="chartOptions"
                    :series="[Math.round((payment.c / total_sales) * 100)]"
                  />
                </div>
              </template>

              <!-- DATA -->
              <div
                class="flex justify-between text-center mt-6"
                slot="no-body-bottom"
              >
                <div
                  class="w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0 border-l-0"
                >
                  <p class="mt-4">Paid In</p>
                  <p class="mb-4 text-3xl font-semibold">
                    {{ Number(payment.a).toLocaleString() }}
                  </p>
                </div>
                <div
                  class="w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0"
                >
                  <p class="mt-4">Sales Count</p>
                  <p class="mb-4 text-3xl font-semibold">
                    {{ Number(payment.c).toLocaleString() }}
                  </p>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
      </vs-tab>
      <!--  -->
      <vs-tab label="Sale Report" @click="changeTab(tabs.sales_report)">
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ShoppingBagIcon"
              icon-right
              :statistic="total_sales"
              statisticTitle="Total Sales"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ShoppingCartIcon"
              icon-right
              :statistic="gross_sales"
              :statisticTitle="`Total Sales Value (${$root.businessData.data.currency})`"
              color="success"
            />
          </div>
        </div>

        <div id="printMe" class="vx-row" v-if="sales && sales.length > 0">
          <div class="vx-col w-full mb-base">
            <vx-card title="Sales">
              <div slot="no-body" class="mt-4">
                <vs-table :data="sales" class="table-dark-inverted">
                  <template slot="thead">
                    <vs-th>NO.</vs-th>
                    <vs-th>SALE ID</vs-th>
                    <vs-th>DATE</vs-th>
                    <vs-th>CASHIER</vs-th>
                    <vs-th v-if="currentCustomer == null">CUSTOMER</vs-th>
                    <vs-th v-if="currentBranch == null">BRANCH</vs-th>
                    <vs-th>PAYMENT METHOD</vs-th>
                    <vs-th>DISCOUNT</vs-th>
                    <vs-th>AMOUNT</vs-th>
                    <vs-th></vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="index" v-for="(tr, index) in data">
                      <vs-td>
                        <span># {{ index + 1 }}</span>
                      </vs-td>
                      <vs-td>
                        <code>{{ tr._id ? tr._id.split(':')[1] : '--' }}</code>
                      </vs-td>
                      <vs-td>
                        <span>{{ parseDate(tr.date_created) }}</span>
                      </vs-td>
                      <vs-td>
                        <span>{{ getUser(tr.cashier) }}</span>
                      </vs-td>
                      <vs-td v-if="currentCustomer == null">
                        <span>{{ tr.customer ? tr.customer.name : '--' }}</span>
                      </vs-td>
                      <vs-td v-if="currentBranch == null">
                        <span>{{ tr.branch.branch_name }}</span>
                      </vs-td>
                      <vs-td
                        :data="data[index].payment_method"
                        :style="[
                          tr.void
                            ? { color: 'grey' }
                            : tr.credit
                              ? { color: 'red' }
                              : { color: 'green' },
                          { fontWeight: '800' },
                        ]"
                      >
                        <span>{{
                          tr.void
                            ? 'VOID'
                            : tr.credit
                              ? 'Credit'
                              : tr.payment
                                ? tr.payment.name
                                : 'N/A'
                        }}</span>
                      </vs-td>
                      <vs-td :data="data[index].discount">
                        <span>
                          {{
                            Number(tr.discount).toLocaleString('en-KE', {
                              style: 'currency',
                              currency: $root.businessData.data.currency,
                            })
                          }}</span
                        >
                      </vs-td>
                      <vs-td :data="data[index].amount">
                        <span>
                          {{
                            Number(tr.amount).toLocaleString('en-KE', {
                              style: 'currency',
                              currency: $root.businessData.data.currency,
                            })
                          }}</span
                        >
                      </vs-td>
                      <vs-td>
                        <feather-icon
                          icon="FileTextIcon"
                          svg-classes="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="$root.getReceipt(tr._id, 'sale')"
                        />
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vx-card>
          </div>
        </div>

        <div class="vx-row mb-base">
          <div class="vx-col w-full mb-base">
            <vx-card
              :subtitle="`Sales between ${this.parseDate(
                this.dateRange.startDate,
              )} - ${this.parseDate(this.dateRange.endDate)}`"
              title="Item Sales"
            >
              <div slot="no-body" class="mt-4">
                <vs-table
                  :data="product_statistics"
                  class="table-dark-inverted"
                >
                  <template slot="thead">
                    <vs-th>SALE ID</vs-th>
                    <vs-th>DATE</vs-th>
                    <vs-th>ITEM CODE</vs-th>
                    <vs-th>ITEM NAME</vs-th>
                    <vs-th>SALE PRICE</vs-th>
                    <vs-th>TOTAL QUANTITY</vs-th>
                    <vs-th>TOTAL AMOUNT</vs-th>
                    <vs-th></vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="index" v-for="(tr, index) in data">
                      <vs-td>
                        <code>{{
                          tr.sale_id ? tr.sale_id.split(':')[1] : '--'
                        }}</code>
                      </vs-td>
                      <vs-td>
                        <span>{{ parseDate(tr.date_created) }}</span>
                      </vs-td>
                      <vs-td>
                        <span>{{ tr.c }}</span>
                      </vs-td>
                      <vs-td>
                        <span>{{ tr.n }}</span>
                      </vs-td>
                      <vs-td>
                        <span>
                          {{
                            Number(tr.s).toLocaleString('en-KE', {
                              style: 'currency',
                              currency: $root.businessData.data.currency,
                            })
                          }}</span
                        >
                      </vs-td>
                      <vs-td>
                        <span>{{ tr.q }}</span>
                      </vs-td>
                      <vs-td>
                        <span>
                          {{
                            Number(tr.t).toLocaleString('en-KE', {
                              style: 'currency',
                              currency: $root.businessData.data.currency,
                            })
                          }}</span
                        >
                      </vs-td>
                      <vs-td>
                        <feather-icon
                          icon="FileTextIcon"
                          svg-classes="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="$root.getReceipt(tr.sale_id, 'sale')"
                        />
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vx-card>
          </div>
        </div>
      </vs-tab>
      <vs-tab label="Purchase Report" @click="changeTab(tabs.purchase_report)">
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="TruckIcon"
              icon-right
              :statistic="purchases ? purchases.length : 0"
              statisticTitle="Total Purchases"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ClockIcon"
              icon-right
              :statistic="total_pending_purchases"
              statisticTitle="Pending Purchases"
              color="warning"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ShoppingBagIcon"
              icon-right
              :statistic="total_purchases"
              :statisticTitle="`Total Purchases Value (${$root.businessData.data.currency})`"
              color="success"
            />
          </div>
        </div>

        <div class="vx-row" v-if="purchases && purchases.length > 0">
          <div class="vx-col w-full mb-base">
            <vx-card title="Purchases">
              <div slot="no-body" class="mt-4">
                <vs-table :data="purchases" class="table-dark-inverted">
                  <template slot="thead">
                    <vs-th>NO.</vs-th>
                    <vs-th>PURCHASE ID</vs-th>
                    <vs-th>PURCHASE DATE</vs-th>
                    <vs-th>SUPPLIER</vs-th>
                    <vs-th>AMOUNT</vs-th>
                    <vs-th>STATUS</vs-th>
                    <vs-th></vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="index" v-for="(tr, index) in data">
                      <vs-td>
                        <span># {{ index + 1 }}</span>
                      </vs-td>
                      <vs-td>
                        <code>{{ tr._id.slice(0, 6) }}</code>
                      </vs-td>
                      <vs-td>
                        <span>{{ parseDate(tr.date_created) }}</span>
                      </vs-td>
                      <vs-td>
                        <span>{{
                          getSupplier(tr.supplier)['supplier_name'] || '--'
                        }}</span>
                      </vs-td>
                      <vs-td>
                        <span>{{
                          Number(tr.amount).toLocaleString('en-KE', {
                            style: 'currency',
                            currency: $root.businessData.data.currency,
                          })
                        }}</span>
                      </vs-td>
                      <vs-td>
                        <vs-chip
                          :color="tr.fulfilled ? 'success' : 'danger'"
                          class="method-status"
                          >{{ tr.fulfilled ? 'FULFILLED' : 'PENDING' }}</vs-chip
                        >
                      </vs-td>
                      <vs-td>
                        <feather-icon
                          icon="FileTextIcon"
                          svg-classes="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="$root.getReceipt(tr._id, 'purchase')"
                        />
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vx-card>
          </div>

          <div class="vx-col w-full mb-base">
            <vx-card
              :subtitle="`Purchases fulfilled between ${this.parseDate(
                this.dateRange.startDate,
              )} - ${this.parseDate(this.dateRange.endDate)}`"
              title="Item Purchases"
            >
              <div slot="no-body" class="mt-4 mb-base">
                <vs-table
                  :data="product_purchase_statistics"
                  class="table-dark-inverted"
                >
                  <template slot="thead">
                    <vs-th>PURCHASE ID</vs-th>
                    <vs-th>PURCHASE DATE</vs-th>
                    <vs-th>ITEM CODE</vs-th>
                    <vs-th>ITEM NAME</vs-th>

                    <vs-th>STARTING STOCK</vs-th>
                    <vs-th>FINAL STOCK</vs-th>
                    <vs-th>RESTOCK QUANTITY</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="index" v-for="(tr, index) in data">
                      <vs-td>
                        <code
                          >{{
                            tr.purchase_id ? tr.purchase_id.slice(0, 6) : '--'
                          }}{{
                            tr.purchases_done > 1
                              ? ` +${tr.purchases_done - 1} more`
                              : ''
                          }}
                          <feather-icon
                            icon="FileTextIcon"
                            svg-classes="w-5 h-5 hover:text-primary stroke-current cursor-pointer"
                            @click.stop="
                              $root.getReceipt(tr.purchase_id, 'purchase')
                            "
                        /></code>
                      </vs-td>
                      <vs-td>
                        <span>{{ parseDate(tr.date_created) }}</span>
                      </vs-td>
                      <vs-td>
                        <span>{{ tr.product_code }}</span>
                      </vs-td>
                      <vs-td>
                        <span>{{ tr.product_name }}</span>
                      </vs-td>

                      <vs-td>
                        <span>{{ tr.previous_stock }}</span>
                      </vs-td>
                      <vs-td>
                        <span>{{ tr.current_stock }}</span>
                      </vs-td>
                      <vs-td>
                        <span>+{{ tr.restock }}</span>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vx-card>
          </div>
        </div>
      </vs-tab>
      <vs-tab label="Items Report" @click="changeTab(tabs.product_report)">
        <div class="vx-row">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ShoppingBagIcon"
              icon-right
              :statistic="parseInt(total_products)"
              statisticTitle="Total Items"
            />
          </div>

          <div
            v-if="currentCustomer == null"
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4"
          >
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="BriefcaseIcon"
              icon-right
              :statistic="parseInt(new_products)"
              statisticTitle="New Additions"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ShoppingCartIcon"
              icon-right
              :statistic="parseInt(gross_sales)"
              :statisticTitle="`Gross Sales (${$root.businessData.data.currency})`"
              color="success"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="TrendingUpIcon"
              icon-right
              :statistic="parseInt(gross_profit)"
              :statisticTitle="`Gross Profit (${$root.businessData.data.currency})`"
              color="success"
            />
          </div>
        </div>

        <div class="vx-row mb-base">
          <div class="vx-col w-full mb-base">
            <vx-card
              :subtitle="`Sales between ${this.parseDate(
                this.dateRange.startDate,
              )} - ${this.parseDate(this.dateRange.endDate)}`"
              title="Item Sales"
            >
              <div slot="no-body" class="mt-4">
                <vs-table
                  :data="product_statistics"
                  class="table-dark-inverted"
                >
                  <template slot="thead">
                    <vs-th>ITEM CODE</vs-th>
                    <vs-th>ITEM NAME</vs-th>
                    <vs-th>AVG. SALE PRICE</vs-th>
                    <vs-th>CURRENT RETAIL PRICE</vs-th>
                    <vs-th>TOTAL QUANTITY</vs-th>
                    <vs-th>TOTAL AMOUNT</vs-th>
                    <vs-th>PROFIT</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="index" v-for="(tr, index) in data">
                      <vs-td>
                        <span>{{ tr.c }}</span>
                      </vs-td>
                      <vs-td>
                        <span>{{ tr.n }}</span>
                      </vs-td>
                      <vs-td>
                        <span>
                          {{
                            Number(tr.ts / tr.ct).toLocaleString('en-KE', {
                              style: 'currency',
                              currency: $root.businessData.data.currency,
                            })
                          }}</span
                        >
                      </vs-td>
                      <vs-td>
                        <span>
                          {{
                            Number(tr.s).toLocaleString('en-KE', {
                              style: 'currency',
                              currency: $root.businessData.data.currency,
                            })
                          }}</span
                        >
                      </vs-td>
                      <vs-td>
                        <span>{{ tr.q }}</span>
                      </vs-td>
                      <vs-td>
                        <span>
                          {{
                            Number(tr.t).toLocaleString('en-KE', {
                              style: 'currency',
                              currency: $root.businessData.data.currency,
                            })
                          }}</span
                        >
                      </vs-td>
                      <vs-td :data="data[index].p">
                        <span
                          class="text-bold"
                          :class="{
                            'text-success': tr.p > 0,
                            'text-danger': tr.p < 0,
                          }"
                        >
                          {{
                            Number(tr.p).toLocaleString('en-KE', {
                              style: 'currency',
                              currency: $root.businessData.data.currency,
                            })
                          }}
                        </span>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </vx-card>
          </div>
          <div class="vx-col w-full mb-base">
            <vx-card title="Item Sales">
              <e-charts :options="pie" ref="pie" />
            </vx-card>
          </div>
        </div>

        <div
          class="vx-col w-full"
          v-if="
            productAddedData &&
            productAddedData.length &&
            currentCustomer == null
          "
        >
          <vx-card
            :subtitle="`Items added between ${this.parseDate(
              this.dateRange.startDate,
            )} - ${this.parseDate(this.dateRange.endDate)}`"
            title="New Items"
          >
            <div slot="no-body" class="mt-4">
              <vs-table :data="productAddedData" class="table-dark-inverted">
                <template slot="thead">
                  <vs-th>#</vs-th>
                  <vs-th>ITEM CODE</vs-th>
                  <vs-th>ITEM NAME</vs-th>
                  <vs-th>RETAIL PRICE</vs-th>
                  <vs-th>WHOLESALE PRICE</vs-th>
                  <vs-th>COST PRICE</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="index" v-for="(tr, index) in data">
                    <vs-td>
                      <span>{{ index + 1 }}</span>
                    </vs-td>
                    <vs-td>
                      <span>{{ tr.product_code }}</span>
                    </vs-td>
                    <vs-td>
                      <span>{{ tr.product_name }}</span>
                    </vs-td>
                    <vs-td>
                      <span>
                        {{
                          Number(
                            tr.retail_price || tr.selling_price,
                          ).toLocaleString('en-KE', {
                            style: 'currency',
                            currency: $root.businessData.data.currency,
                          })
                        }}</span
                      >
                    </vs-td>
                    <vs-td>
                      <span>
                        {{
                          Number(
                            tr.wholesale_price ||
                              tr.retail_price ||
                              tr.selling_price,
                          ).toLocaleString('en-KE', {
                            style: 'currency',
                            currency: $root.businessData.data.currency,
                          })
                        }}</span
                      >
                    </vs-td>
                    <vs-td>
                      <span>
                        {{
                          Number(tr.cost_price).toLocaleString('en-KE', {
                            style: 'currency',
                            currency: $root.businessData.data.currency,
                          })
                        }}</span
                      >
                    </vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </vx-card>
        </div>
      </vs-tab>
      <vs-tab label="Combined Statistics" @click="changeTab(tabs.statistics)">
        <vs-alert
          :active.sync="stat_warning"
          closable
          close-icon="icon-x-circle"
          icon-pack="feather"
          color="warning"
          title="Note"
        >
          The following statistics are based from items still in stock and will
          not calculate sold items. To check sold items choose "Gross Sales"
          then Select a date range to get calculated reports of your sales.
        </vs-alert>
        <div class="vx-row mt-4">
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ShoppingBagIcon"
              icon-right
              :statistic="parseInt(total_products)"
              statisticTitle="Total Items"
            />
          </div>

          <div
            v-if="!currentBranch"
            class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4"
          >
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="GlobeIcon"
              icon-right
              :statistic="branchData ? branchData.length : 0"
              statisticTitle="Total Branches"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="ClipboardIcon"
              icon-right
              :statistic="stock"
              :statisticTitle="`Inventory (${$root.businessData.data.currency})`"
              color="warning"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="AwardIcon"
              icon-right
              :statistic="total_investment"
              :statisticTitle="`Investment (${$root.businessData.data.currency})`"
              color="danger"
            />
          </div>

          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="BarChartIcon"
              icon-right
              :statistic="total_unrealised_profit"
              :statisticTitle="`Expected Return (${$root.businessData.data.currency})`"
              color="success"
            />
          </div>
          <div class="vx-col w-full sm:w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4">
            <statistics-card-line
              hideChart
              class="mb-base"
              icon="PercentIcon"
              icon-right
              :statistic="total_profit_margin"
              statisticTitle="Profit Margin"
              color="success"
            />
          </div>
        </div>
      </vs-tab>
    </vs-tabs>

    <div v-show="false">
      <div :id="`print-${tabs.sales_report}`">
        <!-- Print - Tab 1 (sales) -->
        <div
          class="flex items-center flex-row justify-center mb-6 center text-center"
        >
          <img
            :src="$root.logo ? $root.logo : '@/assets/images/logo/logo.png'"
            height="80px"
            alt="logo"
          />
          <div
            class="flex flex-col justify-items-center"
            style="margin-top: 20px"
          >
            <h2>{{ $root.businessData.data.business_name }}</h2>
            <h4 style="margin-top: 10px">
              {{ $root.businessData.data.postal_address }}
            </h4>
            <h4 style="margin-top: 10px">
              {{ $root.businessData.data.postal_city }}
            </h4>
          </div>
        </div>

        <h5 style="margin-top: 20px">
          SALES REPORT ({{
            this.parseDate(this.dateRange.startDate, 'DD/MM/YYYY')
          }}
          - {{ this.parseDate(this.dateRange.endDate, 'DD/MM/YYYY') }})
        </h5>

        <div style="margin-top: 20px" class="invoice__invoice-detail mt-6">
          <table>
            <tr v-if="currentBranch">
              <th>Branch</th>
              <td>{{ currentBranch ? currentBranch.branch_name : '' }}</td>
            </tr>
            <tr v-if="currentCustomer">
              <th>Customer</th>
              <td>{{ currentCustomer ? currentCustomer.name : '' }}</td>
            </tr>
            <tr v-if="currentSupplier">
              <th>Supplier</th>
              <td>
                {{ currentSupplier ? currentSupplier.supplier_name : '' }}
              </td>
            </tr>

            <tr>
              <th>Start Date</th>
              <td>
                {{ this.parseDate(this.dateRange.startDate, 'DD/MM/YYYY') }}
              </td>
            </tr>
            <tr>
              <th>End Date</th>
              <td>
                {{ this.parseDate(this.dateRange.endDate, 'DD/MM/YYYY') }}
              </td>
            </tr>
          </table>

          <div>
            <table
              cellspacing="0"
              border="1"
              style="
                margin-top: 20px;
                border: 1px solid #ccc;
                td {
                  font-size: 1em;
                }
              "
            >
              <tr>
                <th>NO.</th>
                <th>SALE ID</th>
                <th>DATE</th>
                <th>CASHIER</th>
                <th>PAYMENT METHOD</th>
                <th>AMOUNT</th>
              </tr>

              <tr v-for="(tr, index) in sales" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  <code>{{ tr._id ? tr._id.split(':')[1] : tr._id }}</code>
                </td>
                <td>
                  {{ parseDate(tr.date_created, 'DD/MM/YYYY hh:mm:ss') }}
                </td>
                <td>
                  {{ getUser(tr.cashier) }}
                </td>
                <td>
                  {{
                    tr.void
                      ? 'VOID'
                      : tr.credit
                        ? 'Credit'
                        : tr.payment
                          ? tr.payment.name
                          : 'N/A'
                  }}
                </td>
                <td>
                  {{
                    Number(tr.amount).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    })
                  }}
                </td>
              </tr>
            </table>

            <table class="mt-4">
              <tr>
                <th>TOTAL SALES</th>
                <th>
                  {{
                    Number(gross_sales).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    })
                  }}
                </th>
              </tr>
            </table>

            <table
              cellspacing="0"
              border="1"
              style="
                margin-top: 20px;
                border: 1px solid #ccc;
                td {
                  font-size: 1em;
                }
              "
            >
              <tr>
                <th>SALE ID</th>
                <th>DATE</th>
                <th>ITEM CODE</th>
                <th>ITEM NAME</th>
                <th>RETAIL PRICE</th>
                <th>TOTAL QUANTITY</th>
                <th>TOTAL AMOUNT</th>
              </tr>

              <tr v-for="(tr, index) in product_statistics" :key="index">
                <td>
                  <code>{{
                    tr.sale_id ? tr.sale_id.split(':')[1] : '--'
                  }}</code>
                </td>
                <td>
                  <span>{{ parseDate(tr.date_created) }}</span>
                </td>
                <td>
                  <span>{{ tr.c }}</span>
                </td>
                <td>
                  <span>{{ tr.n }}</span>
                </td>
                <td>
                  <span>
                    {{
                      Number(tr.s).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      })
                    }}</span
                  >
                </td>
                <td>
                  <span>{{ tr.q }}</span>
                </td>
                <td>
                  <span>
                    {{
                      Number(tr.t).toLocaleString('en-KE', {
                        style: 'currency',
                        currency: $root.businessData.data.currency,
                      })
                    }}</span
                  >
                </td>
              </tr>
            </table>

            <table class="mt-4">
              <tr>
                <th>TOTAL SALES</th>
                <th>
                  {{
                    Number(gross_sales).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    })
                  }}
                </th>
              </tr>
            </table>
          </div>

          <div>
            <!-- Total -->
          </div>
        </div>
      </div>

      <div :id="`print-${tabs.purchase_report}`">
        <!-- Print - Tab 2 (purchases) -->
        <div
          class="flex items-center flex-row justify-center mb-6 center text-center"
        >
          <img
            :src="$root.logo ? $root.logo : '@/assets/images/logo/logo.png'"
            height="80px"
            alt="logo"
          />
          <div
            class="flex flex-col justify-items-center"
            style="margin-top: 20px"
          >
            <h2>{{ $root.businessData.data.business_name }}</h2>
            <h4 style="margin-top: 10px">
              {{ $root.businessData.data.postal_address }}
            </h4>
            <h4 style="margin-top: 10px">
              {{ $root.businessData.data.postal_city }}
            </h4>
          </div>
        </div>

        <h5 style="margin-top: 20px">
          PURCHASE REPORT ({{
            this.parseDate(this.dateRange.startDate, 'DD/MM/YYYY')
          }}
          - {{ this.parseDate(this.dateRange.endDate, 'DD/MM/YYYY') }})
        </h5>

        <div class="invoice__invoice-detail mt-6">
          <table style="margin-top: 20px">
            <tr v-if="currentBranch">
              <th>Branch</th>
              <td>{{ currentBranch ? currentBranch.branch_name : '' }}</td>
            </tr>
            <tr v-if="currentCustomer">
              <th>Customer</th>
              <td>{{ currentCustomer ? currentCustomer.name : '' }}</td>
            </tr>
            <tr v-if="currentSupplier">
              <th>Supplier</th>
              <td>
                {{ currentSupplier ? currentSupplier.supplier_name : '' }}
              </td>
            </tr>

            <tr>
              <th>Start Date</th>
              <td>
                {{ this.parseDate(this.dateRange.startDate, 'DD/MM/YYYY') }}
              </td>
            </tr>
            <tr>
              <th>End Date</th>
              <td>
                {{ this.parseDate(this.dateRange.endDate, 'DD/MM/YYYY') }}
              </td>
            </tr>
          </table>

          <div>
            <table
              cellspacing="0"
              border="1"
              style="
                margin-top: 20px;
                border: 1px solid #ccc;
                td {
                  font-size: 1em;
                }
              "
            >
              <tr>
                <th>NO.</th>
                <th>PURCHASE ID</th>
                <th>DATE</th>
                <th>SUPPLIER</th>
                <th>AMOUNT</th>
                <th>STATUS</th>
              </tr>

              <tr v-for="(tr, index) in purchases" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  <code>{{ tr._id ? tr._id.slice(0, 6) : '--' }}</code>
                </td>
                <td>
                  {{ parseDate(tr.date_created, 'DD/MM/YYYY hh:mm:ss') }}
                </td>
                <td>
                  {{ getSupplier(tr.supplier)['supplier_name'] || '--' }}
                </td>
                <td>
                  {{
                    Number(tr.amount).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    })
                  }}
                </td>
                <td>
                  <vs-chip
                    :color="tr.fulfilled ? 'success' : 'danger'"
                    class="method-status"
                    >{{ tr.fulfilled ? 'FULFILLED' : 'PENDING' }}</vs-chip
                  >
                </td>
              </tr>
            </table>

            <table
              cellspacing="0"
              border="1"
              style="
                margin-top: 20px;
                border: 1px solid #ccc;
                td {
                  font-size: 1em;
                }
              "
            >
              <tr>
                <th>PURCHASE ID</th>
                <th>PURCHASE DATE</th>
                <th>ITEM CODE</th>
                <th>ITEM NAME</th>

                <th>STARTING STOCK</th>
                <th>FINAL STOCK</th>
                <th>RESTOCK QUANTITY</th>
              </tr>

              <tr
                v-for="(tr, index) in product_purchase_statistics"
                :key="index"
              >
                <td>
                  <code>{{
                    tr.purchase_id ? tr.purchase_id.slice(0, 6) : '--'
                  }}</code>
                </td>
                <td>
                  <span>{{ parseDate(tr.date_created) }}</span>
                </td>
                <td>
                  <span>{{ tr.product_code }}</span>
                </td>
                <td>
                  <span>{{ tr.product_name }}</span>
                </td>
                <td>
                  <span>{{ tr.previous_stock }}</span>
                </td>
                <td>
                  <span>{{ tr.current_stock }}</span>
                </td>
                <td>
                  <span>+{{ tr.restock }}</span>
                </td>
              </tr>
            </table>

            <table class="mt-4">
              <tr>
                <th>TOTAL PURCHASES</th>
                <th>
                  {{
                    Number(total_purchases).toLocaleString('en-KE', {
                      style: 'currency',
                      currency: $root.businessData.data.currency,
                    })
                  }}
                </th>
              </tr>
            </table>
          </div>

          <div>
            <!-- Total -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue';
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/legend';
import 'echarts/lib/component/tooltip';
import VueApexCharts from 'vue-apexcharts';
import ECharts from 'vue-echarts/components/ECharts';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Datepicker from 'vuejs-datepicker';

import idb from '@/db/idb';
import Roller from 'vue-roller';
import FeatherIcon from '../../../components/FeatherIcon.vue';

export default {
  data() {
    return {
      loading_reports: false,
      date: null,
      date2: new Date(),

      dateRange: {
        startDate: this.getStartDate(new Date()),
        endDate: new Date(),
      },

      tabs: {
        gross_sales: 0,
        purchase_report: 1,
        product_report: 2,
        statistics: 3,
        sales_report: 4,
      },
      currentTab: 0, //tabs.gross_sales,

      stat_warning: true,
      currency_warning: true,
      first_load: true,
      currentBranch: null,
      currentCustomer: null,
      currentSupplier: null,

      total_stock: 0,
      total_investment: 0,
      total_profit_margin: 0,
      total_unrealised_profit: 0,
      total_sales: 0,
      total_jobs: 0,
      gross_sales: 0,
      gross_profit: 0,
      gross_disount: 0,

      total_pending_purchases: 0,
      total_purchases: 0,

      inventory: 0,
      investment: 0,
      unrealised_profit: 0,

      new_products: 0,
      total_products: 0,

      payment_statistics: {},
      product_statistics: {},
      product_purchase_statistics: {},
      new_product: {},

      chartOptions: {
        plotOptions: {
          radialBar: {
            size: 110,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: '#bfc5cc',
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: 18,
                color: '#99a2ac',
                fontSize: '4rem',
              },
            },
          },
        },
        colors: ['#00db89'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#00b5b5'],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        chart: {
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
      },
      pie: {
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        },
        legend: {
          orient: 'vertical',
          bottom: 0,
          top: 0,
          x: 'left',
          left: 0,
          right: 200,
          data: [],
        },
        series: [
          {
            name: 'Item Sales',
            type: 'pie',
            radius: '60%',
            center: ['80%', '50%'],
            // center: ['50%', '60%'],
            // color: ["#FF9F43", "#28C76F", "#EA5455", "#87ceeb", "#7367F0"],
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },
    };
  },
  pouch: {
    saleData() {
      return {
        database: 'express',
        selector: {
          type: 'sale',
          void: false || undefined,
          date_created: {
            $gte:
              new Date(this.getStartDate(this.dateRange.startDate)).getTime() ||
              new Date().getTime(),
            $lt:
              new Date(this.getEndDate(this.dateRange.endDate)).getTime() ||
              new Date().getTime(),
          },
        },
        sort: [
          {
            date_created: 'desc',
          },
        ],
      };
    },
    productData() {
      return {
        database: 'express',
        selector: {
          type: 'product',
        },
      };
    },
    productAddedData() {
      return {
        database: 'express',
        selector: {
          type: 'product',
          date_created: {
            $gte:
              new Date(this.getStartDate(this.dateRange.startDate)).getTime() ||
              new Date().getTime(),
            $lt:
              new Date(this.getEndDate(this.dateRange.endDate)).getTime() ||
              new Date().getTime(),
          },
        },
      };
    },
    POData() {
      return {
        database: 'express',
        selector: {
          type: 'purchase',
          date_created: {
            $gte:
              new Date(this.getStartDate(this.dateRange.startDate)).getTime() ||
              new Date().getTime(),
            $lt:
              new Date(this.getEndDate(this.dateRange.endDate)).getTime() ||
              new Date().getTime(),
          },
        },
      };
    },
    branchData() {
      return {
        database: 'express',
        selector: {
          type: 'branch',
        },
      };
    },
    supplierData() {
      return {
        database: 'express',
        selector: {
          type: 'supplier',
        },
      };
    },
    customerData() {
      return {
        database: 'express',
        selector: {
          type: 'customer',
        },
      };
    },
    userData() {
      return {
        database: 'express',
        selector: {
          type: 'user',
        },
      };
    },
    stockData() {
      return {
        database: 'express',
        selector: {
          type: 'stock',
          // branch_id: this.currentBranch._id || undefined,
        },
      };
    },
  },
  computed: {
    branchOptions() {
      if (this.branchData) {
        if (this.branchData.length == 1) {
          this.changeBranch(this.branchData[0]);
        }
        return this.branchData;
      } else {
        return [];
      }
    },
    supplierOptions() {
      if (this.supplierData) {
        return this.supplierData;
      } else {
        return [];
      }
    },
    customerOptions() {
      if (this.customerData) {
        return this.customerData;
      } else {
        return [];
      }
    },
    stockOptions() {
      if (this.stockData) {
        return this.stockData;
      } else {
        return [];
      }
    },
    validate() {
      return (
        this.dateRange.startDate &&
        this.dateRange.endDate &&
        !this.loading_reports
      );
    },
    products() {
      let p = this.productData || [];
      if (this.currentBranch === null) {
        this.total_products = p.length;
      } else {
        let branch_products = p.filter((product) => {
          if (product['stock']) {
            if (product['stock'].hasOwnProperty(this.currentBranch._id)) {
              return product;
            }
          } else {
            if (product.version === 2) {
              const productStock = this.stockOptions.filter(
                (st) =>
                  st.item_id === product._id &&
                  st.branch_id === this.currentBranch._id,
              );
              return productStock.length > 0 ? product : false;
            } else {
              return false;
            }
          }
        });
        this.total_products = branch_products.length;
      }
      this.new_products = this.productAddedData
        ? this.productAddedData.length
        : [];
      return p;
    },
    purchases() {
      let s = (this.POData || []).filter((e) => {
        if (this.currentSupplier) {
          return e.supplier == this.currentSupplier._id;
        }
        return true;
      });
      let pp = 0;
      let sp = {};
      let tp = 0;

      for (let b = 0; b < s.length; b++) {
        const purchase = s[b];

        if (!purchase.fulfilled) {
          // Pending Purchases
          pp = pp + 1;
        } else {
          // Fulfilled Purchases

          tp = tp + purchase.amount;
        }

        // ====+++========================================= //
        // ====+++========================================= //
        if (this.currentBranch === null) {
          // if (sale.payment) {
          //   const pid = sale.payment._id;
          //   ts = ts + parseInt(sale.amount);
          //   if (ps.hasOwnProperty(pid)) {
          //     ps[pid].c = ps[pid].c + 1;
          //     ps[pid].a = ps[pid].a + parseInt(sale.amount);
          //   } else {
          //     ps[pid] = {
          //       c: 1,
          //       n: sale.payment.name,
          //       a: parseInt(sale.amount),
          //     };
          //   }
          // }
          // for (let a = 0; a < purchase.products.length; a++) {
          //   const product = purchase.products[a];
          //   const pid = product._id;
          //   if (sp.hasOwnProperty(pid)) {
          //     /*
          //       profit
          //       quantity
          //       total amount
          //       count
          //       total sales
          //     */
          //     sp[pid].p =
          //       sp[pid].p +
          //       (parseInt(product.retail_price) -
          //         parseInt(product.cost_price)) *
          //         parseInt(product.q || 1);
          //     sp[pid].q = sp[pid].q + parseInt(product.q || 1);
          //     sp[pid].t =
          //       sp[pid].t +
          //       parseInt(product.q || 1) * parseInt(product.retail_price);
          //     sp[pid].ct = sp[pid].ct + 1;
          //     sp[pid].ts = sp[pid].ts + parseInt(product.retail_price);
          //   } else {
          //     sp[pid] = {
          //       c: product.product_code,
          //       n: product.product_name,
          //       cp: parseInt(product.cost_price),
          //       s: parseInt(product.retail_price),
          //       ts: parseInt(product.retail_price),
          //       p:
          //         (parseInt(product.retail_price) -
          //           parseInt(product.cost_price)) *
          //         parseInt(product.q || 1),
          //       q: parseInt(product.q || 1),
          //       t: parseInt(product.q || 1) * parseInt(product.retail_price),
          //       ct: 1,
          //     };
          //   }
          //   gp +=
          //     (parseInt(product.retail_price) - parseInt(product.cost_price)) *
          //     product.q;
          // }
        } else {
          if (purchase.branch === this.currentBranch._id) {
            // if (sale.payment) {
            //   const pid = sale.payment._id;
            //   ts = ts + parseInt(sale.amount);
            //   if (ps.hasOwnProperty(pid)) {
            //     ps[pid].c = ps[pid].c + 1;
            //     ps[pid].a = ps[pid].a + parseInt(sale.amount);
            //   } else {
            //     ps[pid] = {
            //       c: 1,
            //       n: sale.payment.name,
            //       a: parseInt(sale.amount),
            //     };
            //   }
            // }
            const products = purchase.products.sort((a, b) => {});
            for (let a = 0; a < purchase.products.length; a++) {
              const product = purchase.products[a];
              const pid = product._id;
              const restock_quantity =
                this.currentBranch._id in product['newStock']
                  ? product['newStock'][this.currentBranch._id]
                  : 0;

              let stock = 0;
              let productStock;
              switch (product.version) {
                case 2:
                  productStock = product.stockv2.find(
                    (st2) => st2.branch_id == this.currentBranch._id,
                  );
                  stock = productStock.stock || 0;
                  break;

                default:
                  stock =
                    this.currentBranch._id in product['stock']
                      ? product['stock'][this.currentBranch._id]
                      : 0;
                  break;
              }

              const previous_stock = stock - restock_quantity;
              if (sp.hasOwnProperty(purchase._id + ':' + pid)) {
                /*
                Code
                Name
                Prev Stock
                Current Stock   
                Restock Quantity
              */

                // sp[pid].previous_stock =
                //   sp[pid].previous_stock + previous_stock;

                sp[pid].current_stock = sp[pid].current_stock + stock;
                sp[pid].restock = sp[pid].restock + restock_quantity;

                sp[pid].purchases_done = sp[pid].purchases_done + 1;

                // sp[pid].previous_stock = sp[pid].previous_stock +
                // sp[pid].q = sp[pid].q + parseInt(product.q || 1);
                // sp[pid].t =
                //   sp[pid].t +
                //   parseInt(product.q || 1) * parseInt(product.retail_price);
                // sp[pid].ct = sp[pid].ct + 1;
                // sp[pid].ts = sp[pid].ts + parseInt(product.retail_price);
              } else {
                sp[purchase._id + ':' + pid] = {
                  purchase_id: purchase._id,
                  purchases_done: 1,
                  product_code: product.product_code,
                  product_name: product.product_name,

                  previous_stock: previous_stock,
                  current_stock: stock,
                  restock: restock_quantity,

                  date_created: purchase.date_created,
                };
              }
              // gp +=
              //   (parseInt(product.retail_price) -
              //     parseInt(product.cost_price)) *
              //   product.q;
            }
          }
        }
      }

      // this.$vs.loading.close();
      // this.total_sales = s.length;
      // this.gross_sales = ts;
      // this.gross_profit = gp;
      // this.payment_statistics = ps;
      this.product_purchase_statistics = Object.keys(sp)
        .map((key) => sp[key])
        .sort((a, b) => {
          if (a.product_name.toLowerCase() < b.product_name.toLowerCase()) {
            return -1;
          } else if (
            a.product_name.toLowerCase() > b.product_name.toLowerCase()
          ) {
            return 1;
          } else {
            return 0;
          }
        });

      this.total_pending_purchases = pp;

      this.total_purchases = tp;

      if (this.currentBranch === null) {
        return s;
      } else {
        let branch_purchases = s.filter(
          (purchase) => purchase.branch == this.currentBranch._id,
        );
        // this.total_purchases = branch_purchases.length || 0;
        return branch_purchases;
      }
    },
    sales() {
      let s = (this.saleData || []).filter((sd) => {
        if (this.currentCustomer) {
          return sd.customer && sd.customer._id == this.currentCustomer._id;
        }
        return sd.void == false || undefined;
      });
      let ps = {};
      let ts = 0;
      let tp = 0;
      let sp = {};
      let cp = [];
      let gp = 0;
      let grossDiscount = 0;
      let p = this.products;

      for (let b = 0; b < s.length; b++) {
        grossDiscount += parseInt(s.discount || 0);

        let temp_gp = 0;

        if (this.currentBranch === null) {
          const sale = s[b];
          if (sale.payment) {
            const pid = sale.payment._id;
            ts = ts + parseInt(sale.amount);
            if (ps.hasOwnProperty(pid)) {
              ps[pid].c = ps[pid].c + 1;
              ps[pid].a = ps[pid].a + parseInt(sale.amount);
            } else {
              ps[pid] = {
                c: 1,
                n: sale.payment.name,
                a: parseInt(sale.amount),
              };
            }
          }
          for (let a = 0; a < s[b].products.length; a++) {
            const product = s[b].products[a];
            const pid = product._id;

            if (sp.hasOwnProperty(pid)) {
              /* 
                p profit
                q quantity
                t total amount 
                ct count
                ts total sales

              */

              if (this.isWholesale(sale)) {
                // Wholesale
                sp[pid].p =
                  sp[pid].p +
                  (parseInt(product.sale_price || product.wholesale_price) -
                    parseInt(product.cost_price)) *
                    parseInt(product.q || 1);

                sp[pid].t =
                  sp[pid].t +
                  parseInt(product.q || 1) *
                    parseInt(
                      product.sale_price ||
                        product.wholesale_price ||
                        product.retail_price,
                    );

                sp[pid].ts =
                  sp[pid].ts +
                  parseInt(
                    product.sale_price ||
                      product.wholesale_price ||
                      product.retail_price,
                  );
              } else {
                // Retail
                sp[pid].p =
                  sp[pid].p +
                  (parseInt(product.sale_price || product.retail_price) -
                    parseInt(product.cost_price)) *
                    parseInt(product.q || 1);

                sp[pid].t =
                  sp[pid].t +
                  parseInt(product.q || 1) *
                    parseInt(product.sale_price || product.retail_price);

                sp[pid].ts =
                  sp[pid].ts +
                  parseInt(product.sale_price || product.retail_price);
              }

              sp[pid].q = sp[pid].q + parseInt(product.q || 1);
              sp[pid].ct = sp[pid].ct + 1;
            } else {
              if (this.isWholesale(sale)) {
                sp[pid] = {
                  c: product.product_code,
                  n: product.product_name,
                  cp: parseInt(product.cost_price),
                  s: parseInt(product.retail_price),
                  ts: parseInt(product.sale_price || product.wholesale_price),
                  p:
                    (parseInt(product.sale_price || product.wholesale_price) -
                      parseInt(product.cost_price)) *
                    parseInt(product.q || 1),
                  q: parseInt(product.q || 1),
                  t:
                    parseInt(product.q || 1) *
                    parseInt(product.sale_price || product.wholesale_price),
                  ct: 1,
                };
              } else {
                sp[pid] = {
                  c: product.product_code,
                  n: product.product_name,
                  cp: parseInt(product.cost_price),
                  s: parseInt(product.retail_price),
                  ts: parseInt(product.sale_price || product.retail_price),
                  p:
                    (parseInt(product.sale_price || product.retail_price) -
                      parseInt(product.cost_price)) *
                    parseInt(product.q || 1),
                  q: parseInt(product.q || 1),
                  t:
                    parseInt(product.q || 1) *
                    parseInt(product.sale_price || product.retail_price),
                  ct: 1,
                };
              }
            }

            if (this.isWholesale(sale)) {
              gp +=
                (parseInt(product.sale_price || product.wholesale_price) -
                  parseInt(product.cost_price)) *
                product.q;
            } else {
              gp +=
                (parseInt(product.sale_price || product.retail_price) -
                  parseInt(product.cost_price)) *
                product.q;
            }
          }
        } else {
          let br = this.currentBranch;
          let sl = s[b];
          if (sl.branch._id === this.currentBranch._id) {
            const sale = s[b];
            if (sale.payment) {
              const pid = sale.payment._id;
              ts = ts + parseInt(sale.amount);
              if (ps.hasOwnProperty(pid)) {
                ps[pid].c = ps[pid].c + 1;
                ps[pid].a = ps[pid].a + parseInt(sale.amount);
              } else {
                ps[pid] = {
                  c: 1,
                  n: sale.payment.name,
                  a: parseInt(sale.amount),
                };
              }
            }
            for (let a = 0; a < s[b].products.length; a++) {
              const product = s[b].products[a];
              const pid = product._id;

              if (sp.hasOwnProperty(pid)) {
                /* 
                profit
                quantity
                total amount 
                count
                total sales

              */

                if (this.isWholesale(sale)) {
                  sp[pid].p =
                    sp[pid].p +
                    (parseInt(product.sale_price || product.wholesale_price) -
                      parseInt(product.cost_price)) *
                      parseInt(product.q || 1);
                  sp[pid].q = sp[pid].q + parseInt(product.q || 1);
                  sp[pid].t =
                    sp[pid].t +
                    parseInt(product.q || 1) *
                      parseInt(product.sale_price || product.wholesale_price);
                  sp[pid].ct = sp[pid].ct + 1;
                  sp[pid].ts =
                    sp[pid].ts +
                    parseInt(product.sale_price || product.wholesale_price);
                } else {
                  sp[pid].p =
                    sp[pid].p +
                    (parseInt(product.sale_price || product.retail_price) -
                      parseInt(product.cost_price)) *
                      parseInt(product.q || 1);
                  sp[pid].q = sp[pid].q + parseInt(product.q || 1);
                  sp[pid].t =
                    sp[pid].t +
                    parseInt(product.q || 1) *
                      parseInt(product.sale_price || product.retail_price);
                  sp[pid].ct = sp[pid].ct + 1;
                  sp[pid].ts =
                    sp[pid].ts +
                    parseInt(product.sale_price || product.retail_price);
                }
              } else {
                if (this.isWholesale(sale)) {
                  sp[pid] = {
                    sale_id: sale._id,
                    _id: sale._id,
                    c: product.product_code,
                    n: product.product_name,
                    cp: parseInt(product.cost_price),
                    s: parseInt(product.wholesale_price),
                    ts: parseInt(product.sale_price || product.wholesale_price),
                    p:
                      (parseInt(product.sale_price || product.wholesale_price) -
                        parseInt(product.cost_price)) *
                      parseInt(product.q || 1),
                    q: parseInt(product.q || 1),
                    t:
                      parseInt(product.q || 1) *
                      parseInt(product.sale_price || product.wholesale_price),
                    ct: 1,
                  };
                } else {
                  sp[pid] = {
                    sale_id: sale._id,
                    _id: sale._id,
                    c: product.product_code,
                    n: product.product_name,
                    cp: parseInt(product.cost_price),
                    s: parseInt(product.retail_price),
                    ts: parseInt(product.sale_price || product.retail_price),
                    p:
                      (parseInt(product.sale_price || product.retail_price) -
                        parseInt(product.cost_price)) *
                      parseInt(product.q || 1),
                    q: parseInt(product.q || 1),
                    t:
                      parseInt(product.q || 1) *
                      parseInt(product.sale_price || product.retail_price),
                    ct: 1,
                  };
                }
              }

              if (this.isWholesale(sale)) {
                gp +=
                  (parseInt(product.sale_price || product.wholesale_price) -
                    parseInt(product.cost_price)) *
                  product.q;
              } else {
                gp +=
                  (parseInt(product.sale_price || product.retail_price) -
                    parseInt(product.cost_price)) *
                  product.q;
              }
            }
          }
        }

        this.pie.series[0].data = [];
        this.pie.series[0].color = [];
        this.pie.legend.data = [];
        for (const k in sp) {
          if (sp.hasOwnProperty(k)) {
            const pd = sp[k];
            this.pie.legend.data.push(
              `${pd.n} (${pd.q}) ${Number(pd.t).toLocaleString('en-KE', {
                style: 'currency',
                currency: this.$root.businessData.data.currency,
              })}`,
            );
            this.pie.series[0].data.push({
              value: pd.t, //pd.ct,
              name: `${pd.n} (${pd.q}) ${Number(pd.t).toLocaleString('en-KE', {
                style: 'currency',
                currency: this.$root.businessData.data.currency,
              })}`,
            });
          }
        }
      }
      this.$vs.loading.close();
      this.total_sales = s.length;
      this.gross_sales = ts;
      this.gross_profit = gp - grossDiscount;
      this.gross_disount = grossDiscount;
      this.payment_statistics = ps;
      this.product_statistics = sp;
      if (s.length < 1 && !this.first_load) {
        this.$vs.loading.close();
        this.$vs.notify({
          color: 'warning',
          title: `Sales Unavailable`,
          text: 'Sales records for the selected period are currently unavailable',
        });
      }
      this.first_load = false;
      if (this.currentBranch === null) {
        return s;
      } else {
        let branch_sales = s.filter(
          (sale) => sale.branch._id == this.currentBranch._id,
        );
        this.total_sales = branch_sales.length || 0;
        return branch_sales;
      }
    },
    stock() {
      let ai = 0;
      let ts = 0;
      let ti = 0;
      let te = 0;
      let tup = 0;
      let p = this.products ? this.products : [];
      for (let i = 0; i < p.length; i++) {
        const e = p[i];
        let p_cp = parseInt(e['cost_price']);
        let p_rp = parseInt(e['retail_price']);
        let p_p = p_rp - p_cp;
        switch (e.version) {
          case 2:
            if (this.currentBranch === null) {
              // Loop through stock v2
              const stockRecord = this.stockOptions.filter(
                (st) => st.item_id === e._id,
              );

              stockRecord.forEach((stockItem) => {
                const p_stock = stockItem.stock;
                ts = ts + parseInt(p_stock) * p_rp;
                ti = ti + parseInt(p_stock) * p_cp;
              });
            } else {
              const stockItem = this.stockOptions.find(
                (st) =>
                  st.branch_id == this.currentBranch._id &&
                  st.item_id === e._id,
              );
              if (stockItem) {
                console.log('stockItem', stockItem);
                let brst = stockItem.stock;
                ts = ts + parseInt(brst) * p_rp;
                ti = ti + parseInt(brst) * p_cp;
              } else {
                console.log('ignored ', e.name);
              }
            }
            break;

          default:
            if (this.currentBranch === null) {
              for (const k in e['stock']) {
                if ('stock' in e && e['stock'].hasOwnProperty(k)) {
                  const p_stock = e['stock'][k];
                  ts = ts + parseInt(p_stock) * p_rp;
                  ti = ti + parseInt(p_stock) * p_cp;
                }
              }
            } else {
              let br = this.currentBranch;
              if ('stock' in e && e['stock'].hasOwnProperty(br._id)) {
                let brst = e['stock'][br._id];
                ts = ts + parseInt(brst) * p_rp;
                ti = ti + parseInt(brst) * p_cp;
              }
            }
            break;
        }
      }
      this.total_stock = ts;
      this.total_investment = ti;
      this.total_unrealised_profit = ts - ti;
      this.total_profit_margin =
        (this.total_unrealised_profit / this.total_stock) * 100;

      return ts;
    },
  },
  methods: {
    async printSummary() {
      await this.$htmlToPaper(`print-${this.currentTab}`);
    },
    changeTab(tab) {
      this.currentCustomer = null;
      this.currentSupplier = null;

      this.currentTab = tab;
    },
    changeBranch(b) {
      this.currentBranch = b;
    },
    changeSupplier(s) {
      this.currentSupplier = s;
    },
    changeCustomer(c) {
      this.currentCustomer = c;
    },
    async getRecord(type) {
      return await idb.getRecord(type);
    },
    async fill(a, t) {
      let d = (await this.getRecord(t)) || null;
      if (d) {
        for (let i = 0; i < a.length; i++) {
          const e = a[i];
          if (this.hasOwnProperty(e) && d.hasOwnProperty(e)) {
            this[`${e}`] = d[e];
          }
        }
        return d;
      }
      return false;
    },
    generateColor(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = '#';
      for (var ii = 0; ii < 3; ii++) {
        var value = (hash >> (ii * 8)) & 0xff;
        colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    },
    addDays(date, days) {
      const c = new Date(Number(date));
      c.setDate(date.getDate() + days);
      return c;
    },
    getUser(id) {
      var b = 'N/A';
      if (this.userData !== null) {
        var c = this.userData.find((e) => e._id == id);
        if (typeof c == 'object') {
          b = c.name;
        }
      }
      return b;
    },
    getSupplier(id) {
      if (!this.supplierData || this.supplierData.length < 1) {
        return {};
      }
      return this.supplierData.find((e) => e._id == id);
    },
    getStartDate(d) {
      d.setHours(0, 0, 0, 0);
      return d;
    },
    getEndDate(d) {
      d.setHours(23, 59, 59, 59);
      return d;
    },
    getProduct(z) {
      var data = this.$store.state.products.products.find((x) => x.id == z);
      if (data) {
        return data['product_name'];
      }
      return;
    },
    parseDate(x, format = 'ddd DD MMM YYYY hh:mm a') {
      return this.$moment(x).format(format);
    },
    getDate(x) {
      return this.$moment(x).format('YYYY-MM-DD hh:mm:ss');
    },
    getPaymentMethod(z) {
      var data = this.$store.state.finance.paymentMethods.find(
        (x) => x.id == z,
      );
      if (data) {
        return data;
      }
      return;
    },
    isWholesale(saleData) {
      if (saleData && saleData.customer) {
        if (saleData.customer.hasOwnProperty('wholesale')) {
          return saleData.customer.wholesale || false;
        }
      }
      return false;
    },
  },
  components: {
    VueApexCharts,
    StatisticsCardLine,
    ChangeTimeDurationDropdown,
    DateRangePicker,
    Datepicker,
    Roller,
    FeatherIcon,
    ECharts,
  },
  async created() {
    this.$vs.loading();
  },
  mounted() {
    // let dataIndex = -1;
    // let pie = this.$refs.pie;
    // let dataLen = this.pie.options.series[0].data.length;
    // setInterval(() => {
    //   pie.dispatchAction({
    //     type: "downplay",
    //     seriesIndex: 0,
    //     dataIndex,
    //   });
    //   dataIndex = (dataIndex + 1) % dataLen;
    //   pie.dispatchAction({
    //     type: "highlight",
    //     seriesIndex: 0,
    //     dataIndex,
    //   });
    //   pie.dispatchAction({
    //     type: "showTip",
    //     seriesIndex: 0,
    //     dataIndex,
    //   });
    // }, 1000);
  },
};
</script>

<style lang="scss">
@media print {
  //use your CSS over here customize your prints components.
  ::v-deep .right-sidebar,
  ::v-deep header {
    display: none !important;
  }
  ::v-deep footer {
    display: none !important;
  }

  @page {
    size: landscape;
  }
}
</style>
